"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var react_redux_1 = require("react-redux");
var apps_1 = require("./actions/apps");
var general_1 = require("./actions/general");
var selectors_1 = require("./selectors");
var utils_1 = require("./utils");
var layout_1 = require("./actions/layout");
var schema_1 = __importStar(require("./schema"));
var createGoogleEvent = function (e) {
    var _a, _b;
    return ({
        event: 'trackEvent',
        eventCat: e.category,
        eventAction: e.action,
        eventLabel: e.label,
        eventValue: (_a = e.value, (_a !== null && _a !== void 0 ? _a : 0)),
        nonInteraction: (_b = e.nonInteraction, (_b !== null && _b !== void 0 ? _b : false))
    });
};
var send = function (events) {
    var _a;
    if (window.dataLayer) {
        (_a = window.dataLayer).push.apply(_a, events);
    }
};
var getEvents = function (state, action) {
    switch (action.type) {
        case apps_1.setApp.type: {
            if (action.payload.appType) {
                return {
                    category: 'Estimate',
                    action: 'App Type Selection',
                    label: schema_1["default"].appType[action.payload.appType].meta.name
                };
            }
            if (action.payload.estimateType) {
                return {
                    category: 'Estimate',
                    action: 'Internal Link',
                    label: action.payload.label
                };
            }
            return {
                category: 'Estimate',
                action: 'Change App Data',
                label: action.payload.name
            };
        }
        case layout_1.showSelectionPanel.type: {
            var _a = action.payload, content = _a.content, edit = _a.edit;
            var category = schema_1.getCategoryName(content);
            var option = schema_1.getSchemaOptions(content);
            var options = utils_1.values(option.options);
            return {
                category: 'Estimate',
                action: category + " " + (edit ? 'Edit' : 'Add to') + " Estimate",
                label: (options.length === 1 ? options[0] : option).meta.name,
                value: options.map(function (o) { return o.cost; }).join(',')
            };
        }
        case apps_1.setAppCounts.type:
        case general_1.setGeneralCounts.type: {
            return utils_1.entries(action.payload.types).map(function (_a) {
                var type = _a[0], count = _a[1];
                var option = schema_1.getSchemaOption(type);
                var category = schema_1.getCategoryName(type);
                return {
                    category: 'Estimate',
                    action: category + " Selection",
                    label: count + " - " + option.meta.name,
                    value: option.cost * count
                };
            });
        }
        case layout_1.showEstimatePanel.type: {
            var totalCost = selectors_1.getTotalCost(state);
            // Only log the event for when the panel is shown for the first time
            // and not as part of a panel change
            return (!state.layout.previousPanel && {
                category: 'Estimate',
                action: 'Estimate Summary Click',
                label: totalCost,
                value: totalCost
            });
        }
        case layout_1.showSpecsModal.type: {
            // not 100% sure how to structure this/if I should use 'value'
            return {
                category: 'Estimate',
                action: 'Internal Link',
                label: action.payload.productType
            };
        }
    }
};
exports.createAnalytics = function () { return function (store) { return function (next) { return function (action) {
    var _a;
    var result = next(action);
    if (!((_a = action) === null || _a === void 0 ? void 0 : _a.type))
        return result;
    var state = store.getState();
    var events = getEvents(state, action);
    if (events) {
        send((Array.isArray(events) ? events : [events])
            .map(function (event) { return (__assign(__assign({}, event), { category: "Pricing " + event.category, action: (selectors_1.isGuided(state, undefined) ? 'Guided' : 'Unguided') + " | " + event.action })); })
            .map(createGoogleEvent));
    }
    return result;
}; }; }; };
exports.useAnalytics = function () {
    var guided = react_redux_1.useSelector(function (s) { return selectors_1.isGuided(s, undefined); });
    // Don't call createGoogleEvent on this object since that is handled by Heroku.Trackable
    return function (e) {
        var _a;
        return (_a = {},
            _a['data-trackable'] = JSON.stringify(__assign(__assign({}, e), { category: "Pricing " + e.category, action: (guided ? 'Guided' : 'Unguided') + " | " + e.action })),
            _a);
    };
};
