"use strict";
exports.__esModule = true;
var toolkit_1 = require("@reduxjs/toolkit");
var share_1 = require("./share");
var PREFIX = 'LAYOUT/';
exports.showSelectionPanel = toolkit_1.createAction(PREFIX + "SHOW_SELECTION_PANEL");
exports.showEstimatePanel = toolkit_1.createAction(PREFIX + "SHOW_ESTIMATE_PANEL");
exports.showHowToPurchasePanel = toolkit_1.createAction(PREFIX + "HOW_TO_PURCHASE_PANEL");
exports.hidePanel = toolkit_1.createAction(PREFIX + "HIDE_PANEL");
exports.showSpecsModal = toolkit_1.createAction(PREFIX + "SHOW_SPECS_MODAL");
exports.hideSpecsModal = toolkit_1.createAction(PREFIX + "HIDE_SPECS_MODAL");
var HIDE_SERVER_ERROR = PREFIX + "HIDE_SERVER_ERROR";
exports.hideServerError = function () { return function (dispatch) {
    dispatch(share_1.setUrl());
    dispatch({ type: HIDE_SERVER_ERROR });
}; };
exports.hideServerError.type = HIDE_SERVER_ERROR;
exports.showPersistWarning = toolkit_1.createAction(PREFIX + "SHOW_PERSIST_WARNING");
var HIDE_PERSIST_WARNING = PREFIX + "HIDE_PERSIST_WARNING";
exports.hidePersistWarning = function () { return function (dispatch) {
    dispatch(share_1.setUrl());
    dispatch({ type: HIDE_PERSIST_WARNING });
}; };
exports.hidePersistWarning.type = HIDE_PERSIST_WARNING;
