"use strict";
var _a;
exports.__esModule = true;
var toolkit_1 = require("@reduxjs/toolkit");
var layout_1 = require("../actions/layout");
var share_1 = require("../actions/share");
var initialState = {
    previousPanel: null,
    selectionPanel: null,
    howToPurchasePanel: false,
    estimatePanel: false,
    showPanel: false,
    specsModal: null,
    // serverError on showOnNextAction are true because those will only be shown
    // based on data from the server
    serverError: true,
    persistWarning: {
        show: false,
        showOnNextAction: true,
        nextAction: null
    }
};
exports["default"] = toolkit_1.createReducer(initialState, (_a = {},
    _a[layout_1.showSelectionPanel.type] = function (state, action) {
        var _a;
        if (state.estimatePanel) {
            state.previousPanel = true;
        }
        state.selectionPanel = {
            content: action.payload.content,
            edit: (_a = action.payload.edit, (_a !== null && _a !== void 0 ? _a : false))
        };
        state.howToPurchasePanel = false;
        state.estimatePanel = false;
        state.showPanel = true;
    },
    _a[layout_1.showEstimatePanel.type] = function (state) {
        if (state.selectionPanel) {
            state.previousPanel = state.selectionPanel;
        }
        state.selectionPanel = null;
        state.howToPurchasePanel = false;
        state.estimatePanel = true;
        state.showPanel = true;
    },
    _a[layout_1.showHowToPurchasePanel.type] = function (state) {
        state.selectionPanel = null;
        state.estimatePanel = false;
        state.howToPurchasePanel = true;
        state.showPanel = true;
    },
    _a[layout_1.hidePanel.type] = function (state) {
        state.previousPanel = null;
        state.estimatePanel = false;
        state.howToPurchasePanel = false;
        state.showPanel = false;
    },
    _a[layout_1.showSpecsModal.type] = function (state, action) {
        state.specsModal = action.payload;
    },
    _a[layout_1.hideSpecsModal.type] = function (state) {
        state.specsModal = null;
    },
    _a[layout_1.hideServerError.type] = function (state) {
        state.serverError = false;
    },
    _a[layout_1.showPersistWarning.type] = function (state, action) {
        state.persistWarning.show = true;
        state.persistWarning.showOnNextAction = false;
        state.persistWarning.nextAction = action.payload.nextAction;
    },
    _a[layout_1.hidePersistWarning.type] = function (state) {
        state.persistWarning.show = false;
        state.persistWarning.showOnNextAction = false;
        state.persistWarning.nextAction = null;
    },
    _a[share_1.setServerData.type] = function (state) {
        state.persistWarning.show = false;
        state.persistWarning.showOnNextAction = true;
        state.persistWarning.nextAction = null;
    },
    _a));
