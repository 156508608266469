"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var toolkit_1 = require("@reduxjs/toolkit");
var shareActions = __importStar(require("../actions/share"));
var utils_1 = require("../utils");
var SHARE_TYPES = utils_1.entries({
    shareLink: 'link',
    sendEmail: 'email',
    createPdf: 'pdf',
});
var initialState = __assign({}, SHARE_TYPES.reduce(function (acc, _a) {
    var actionKey = _a[0], stateKey = _a[1];
    acc[stateKey] = {
        fetching: false,
        success: false,
        error: null,
    };
    return acc;
}, {}));
exports["default"] = toolkit_1.createReducer(initialState, __assign({}, SHARE_TYPES.reduce(function (acc, _a) {
    var actionKey = _a[0], stateKey = _a[1];
    acc[shareActions[actionKey + "Start"].type] = function (state, action) {
        state[stateKey].fetching = true;
        state[stateKey].success = false;
        state[stateKey].error = null;
    };
    acc[shareActions[actionKey + "Success"].type] = function (state, action) {
        state[stateKey].fetching = false;
        state[stateKey].success = true;
        state[stateKey].error = null;
    };
    acc[shareActions[actionKey + "Error"].type] = function (state, action) {
        state[stateKey].fetching = false;
        state[stateKey].success = false;
        state[stateKey].error = action.payload.error;
    };
    return acc;
}, {})));
