"use strict";
exports.__esModule = true;
exports.ESTIMATE_GUIDED = 'guided';
exports.ESTIMATE_UNGUIDED = 'self_service';
exports.APP_TYPE_ECO_AND_BASIC = 'eco_and_basic';
exports.APP_TYPE_PRODUCTION = 'production';
exports.APP_TYPE_ADVANCED = 'advanced';
exports.APP_TYPE_ENTERPRISE = 'enterprise';
exports.MEDIA_MOBILE = '(max-width: 639px)';
exports.MEDIA_MOBILE_LARGE = '(min-width: 640px) and (max-width: 767px)';
exports.MEDIA_TABLET = '(min-width: 768px) and (max-width: 1023px)';
exports.MEDIA_DESKTOP = '(min-width: 1024px) and (max-width: 1278px)';
exports.MEDIA_WIDESCREEN = '(min-width: 1280px)';
