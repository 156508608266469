"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var react_1 = __importStar(require("react"));
var Hider = function (_a) {
    var delay = _a.delay, visible = _a.visible, children = _a.children;
    var _b = react_1.useState(visible), isVisible = _b[0], setIsVisible = _b[1];
    var timeoutRef = react_1.useRef(null);
    var prevVisibility = react_1.useRef(visible);
    react_1.useEffect(function () {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (visible === false && prevVisibility.current === true) {
            timeoutRef.current = setTimeout(function () {
                setIsVisible(false);
                prevVisibility.current = false;
            }, delay);
        }
        else {
            setIsVisible(visible);
            prevVisibility.current = visible;
        }
    }, [delay, visible, prevVisibility.current]);
    return isVisible ? react_1["default"].createElement(react_1["default"].Fragment, null, children) : null;
};
exports.withHider = function (Component) {
    return function (_a) {
        var delay = _a.delay, visible = _a.visible;
        return (react_1["default"].createElement(Hider, { delay: delay, visible: visible },
            react_1["default"].createElement(Component, null)));
    };
};
exports["default"] = Hider;
