"use strict";
exports.__esModule = true;
exports["default"] = (function (url) {
    if (url.startsWith('#'))
        return true;
    // Strips off any subdomains
    return (new URL(url).hostname
        .split('.')
        .slice(-2)
        .join('.') === 'heroku.com');
});
