"use strict";
exports.__esModule = true;
var react_1 = require("react");
function useClickOutside(callback, options) {
    var container = react_1.useRef(null);
    var _a = react_1.useState(false), isTouchEvent = _a[0], setTouchEvent = _a[1];
    var eventType = isTouchEvent ? 'touchend' : 'click';
    function handleEvent(e) {
        var _a, _b, _c;
        if ((e.type === 'click' && isTouchEvent) ||
            !callback ||
            !container.current ||
            e.target === null ||
            container.current.contains(e.target) || ((_c = (_b = (_a = options) === null || _a === void 0 ? void 0 : _a.includeRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.contains(e.target))) {
            return;
        }
        callback(e);
    }
    react_1.useEffect(function () {
        document.addEventListener(eventType, handleEvent, true);
        return function () {
            document.removeEventListener(eventType, handleEvent, true);
        };
    });
    react_1.useEffect(function () {
        setTouchEvent('ontouchstart' in document.documentElement);
    }, []);
    return container;
}
exports["default"] = useClickOutside;
