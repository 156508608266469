"use strict";
exports.__esModule = true;
exports.entries = Object.entries;
exports.values = Object.values;
exports.pick = function (obj) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    // keys can be arguments or a single array
    if (keys.length === 1 && Array.isArray(keys[0])) {
        keys = keys[0];
    }
    return keys.reduce(function (acc, key) {
        acc[key] = obj[key];
        return acc;
    }, {});
};
