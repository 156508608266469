"use strict";
exports.__esModule = true;
var SpecsTablePostgres_1 = require("./SpecsTablePostgres");
exports.SpecsTablePostgres = SpecsTablePostgres_1["default"];
var SpecsTableDynos_1 = require("./SpecsTableDynos");
exports.SpecsTableDynos = SpecsTableDynos_1["default"];
var SpecsTableRedis_1 = require("./SpecsTableRedis");
exports.SpecsTableRedis = SpecsTableRedis_1["default"];
var SpecsTableKafka_1 = require("./SpecsTableKafka");
exports.SpecsTableKafka = SpecsTableKafka_1["default"];
var SpecsTableCollaborator_1 = require("./SpecsTableCollaborator");
exports.SpecsTableCollaborator = SpecsTableCollaborator_1["default"];
var SpecsTableCiCd_1 = require("./SpecsTableCiCd");
exports.SpecsTableCiCd = SpecsTableCiCd_1["default"];
var SpecsTableSupport_1 = require("./SpecsTableSupport");
exports.SpecsTableSupport = SpecsTableSupport_1["default"];
var SpecsTableConnect_1 = require("./SpecsTableConnect");
exports.SpecsTableConnect = SpecsTableConnect_1["default"];
